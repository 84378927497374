@font-face {
    font-family: 'Balboa';
    src: url('assets/fonts/Balboa-Medium.eot');
    src: url('assets/fonts/Balboa-Medium.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/Balboa-Medium.woff2') format('woff2'),
        url('assets/fonts/Balboa-Medium.woff') format('woff'),
        url('assets/fonts/Balboa-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.row {
	margin-right: 0;
	margin-left: 0;
}

:focus {
	outline: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
	font-family: "untitled-font-1";
	src:url("assets/fonts/untitled-font-1.eot");
	src:url("assets/fonts/untitled-font-1.eot?#iefix") format("embedded-opentype"),
	  url("assets/fonts/untitled-font-1.woff") format("woff"),
	  url("assets/fonts/untitled-font-1.ttf") format("truetype"),
	  url("assets/fonts/untitled-font-1.svg#untitled-font-1") format("svg");
	font-weight: normal;
	font-style: normal;
}
  
.myicon {
	font-weight: 900;
	font-family: "untitled-font-1" !important;
	content: attr(data-icon);
	font-style: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.myicon-peche:before {
	content: "\61";
}

.myicon-chasse:before {
	content: "\62";
}
  