label {
	color: #ffffff;
	font-size: 3.5vh;
	font-family: "Balboa";
}

.form-input {
	color: #ffffff;
	font-size: 3.5vh;
	font-family: 'Balboa';
	background-color: #000000;
	border-radius: 5px;
	border: 1px solid white;
	padding: 10px;
	width: 100%;	
}

.form-input-focus, .form-input:focus {
	border-color: #FBA00B;
}

.form-label-focus {
	transition-duration: 0.4s;
	color: #FBA00B;
}

.form-button {
	border-radius: 5px;
	padding: 10px;
	text-align: center;
	background-color: rgba(255, 255, 255, 0.719);
	color: #ffffff;
	font-family: "Balboa";
	font-size: 4vh;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
}

.form-button:hover, .form-button-focus {
	background-color: #FBA00B;
	cursor: pointer;
}