html, body {
	padding: 0;
	margin: 0;
	height: 100%;
	background-color: #444;
	font-family: 'Balboa';
	overflow: hidden;
}

:focus {
	outline: 0;
}

#container {
	height: 100%;
}

#sidebar {
	position: absolute;
	left: -27vh;
	top: 0;
	width: 20vw;
	height: 100%;
	box-shadow: 2px 0 20px 0 black;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	transition: left 0.5s, background-color 0.3s;
	background-color: rgba(36, 36, 36, 0.9);
	z-index: 100;
}

#sidebar:hover {
	background-color: rgba(255, 255, 255, 0.2);
}

#sidebar.focused {
	left: 0;
}

#icons {
	margin-right: 22px;
	transition: opacity 0.5s;
	z-index: 10;
}

#icons div {
	text-align: center;
	margin: 5vh 0;
}

#icons .fa {
	color: #ccc;
	font-size: 3vh;
}

#icons .myicon {
	color: #ccc;
	font-size: 3vh;
}

#sidebar.focused #icons {
	opacity: 0;
}

#menu {
	width: 100%;
	height: 100%;
	/*background-color: red; */
	background-color: rgb(36, 36, 36);
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	transition: opacity 0.5s;
	box-sizing: border-box;
	padding-top: 70px;
}

#sidebar.focused #menu {
	opacity: 1;
}
  
#menu .item {
	height: 10vh;
	line-height: 10vh;
	color: #FBA00B;
	font-size: 3vh;
	padding-left: 5vh;
	box-sizing: border-box;
	cursor: default;
	display: none;
	cursor: pointer;
}

#menu .item:hover {
	background-color: rgba(36, 36, 36, 0.8);
}
#menu .item-selected {
	background-color: rgba(84, 84, 84, 0.8);
}
#menu .item-focus {
	background-color: #FBA00B;
	color: white !important;
}

#menu .item .fa {
	width: 40px;
}

#menu .item .myicon {
	padding-right:15px;
	width: 40px;
}

#sidebar.focused #menu .item {
	display: block;
}

#slider{
	width: 100%;
	height:40vh;
	background-color: red;
}
.mainbox {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	/*padding: 40px 40px 0 120px;*/
	padding: 5px 5px 0 5.2vw;
}
  
#search-box-placeholder {
	width: 70%;
	height: 50px;
	line-height: 50px;
	background-color: #666;
	box-sizing: border-box;
	padding-left: 15px;
	cursor: pointer;
	font-size: 2vh;
	color: #aaa;
}

#search-box-placeholder:hover,
.search-box-placeholder-focus {
	color: black !important;
	background-color: white !important;
}
  
#content, #contentSearch {
	height: 100%;
	position: relative;
}

#content .content, #contentSearch .content {
	white-space: nowrap;
	font-size: 0;
	overflow: hidden;
	padding: 50px;
	margin: -50px;
}
  
#content h1, #contentSearch h1 {
	font-size: 4vh;
	padding-top:4vh;
/*	height: 9vh;
	padding: 0; */
	margin: 0;
/*	line-height: 9vh; */
	color:#fff;
}
#content .item, #contentSearch .item {
	display: inline-block;
	position: relative;
	width: 20vw; 
	height: 18vh; 
	/*padding-bottom: 50px; */
	background-color: #666;
	font-size: 1rem;
	margin-right: 2vh;
	border-radius: 10px;
	cursor: pointer;
}

#content .item p, #contentSearch .item p{
	position:relative;
	text-align: center;
	bottom:5px;
	color:#FBA00B;
}
  
#content .item-focus, #contentSearch .item-focus {
	background-color: white;
	transform: scale(1.08);
	transition: all .2s ease-in-out;
	box-shadow: inset 0px 0px 9px 15px rgba(251,160,11,1);
	-moz-box-shadow: inset 0px 0px 9px 15px rgba(251,160,11,1);
	-webkit-box-shadow: inset 0px 0px 9px 15px rgba(251,160,11,1);
	border-radius:16px;
}
  
#content .animate, #contentSearch .animate {
	width: 25%;
	padding-bottom: 0;
	transition: padding-bottom 0.3s ease;
}
  
#content .placeholder, #contentSearch .placeholder {
	width: 25%;
	padding-bottom: calc(30% + 80px);
}
  
.contentgroup {
	
	width: 100%;
	z-index: 2;
	opacity: 1;
	transition: all .2s ease-in-out;
	
}
  
.contentgroup.fading-out {
opacity: 0;
display: none;
}
.logoMenu{
	text-align: center;
	padding-bottom:5vh;
	margin:0;
}
.MonPlayer{
	width:100% !important;
	height:40vh !important;
}
.TextVignette{
	position: absolute;
	color:#FFF;
	text-align: center;
	bottom: 0.2vh;
	right:0;
	left:0;
	font-size:2.2vh;
	text-shadow: 0px 0px 12px #000000;
}

.carousel-focus{
	border: 5px solid #fba00b;
	border-radius: 5px;
	transition: all .2s ease-in-out;
}

.itemFocus {
	border: 5px solid #fba00b;
	border-radius: 5px;
	transition: all .2s ease-in-out;
}
.carousel-focus .carousel .control-dots .dot{
	background : #fba00b;
}
